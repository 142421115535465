import React from 'react'

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"

// Hooks
import useIndex from '../hooks/useIndex'

export default function NotFoundPage() {
  const { mode } = useIndex()

  return (
    <Layout mode={mode}>
      <SEO title="404: Not found" />
      <div className={`container__not-found container__not-found--${mode}`}>
        <h1 className={`not-found__title not-found__title--${mode}`}>404: Not Found</h1>
        <p className={`not-found__message not-found__message--${mode}`}>Opsss... I'm sorry this page doesn't exist</p>
      </div>
    </Layout>
  )
}